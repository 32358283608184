<template>
  <v-layout row>
    <v-flex xs12>
      <v-alert
          
          type="warning"
          
        >
          데모페이지에 도메인을 연결했습니다
          sellernote.co.kr<br />
          
        </v-alert>
        <v-alert
          
          type="warning"
          
        >
          견적서 엑셀파일을 업로드 할 수 있습니다
          
          
        </v-alert>
      <v-alert
          outlined
          type="warning"
          prominent
        >
          전체 개발 상태를 실시간 확인 할 수 있습니다.<br />
          아직은 화면이 준비가 안되었네요<br />
          
        </v-alert>
        <div>
          <v-row justify="space-between">
              
            <v-col cols="12" md="8">
            </v-col>
            <v-col cols="12" md="4">
             
            </v-col>
          </v-row>
        </div>
    </v-flex>
  </v-layout>

</template>

<script>

export default {
  components: {
   
  },
  data () {
    return {
       
      
     
    };
  },
  created () {
    
  
  },
  mounted () {
   

    //
  },
  methods: {
    
  }
};
</script>
